import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";
import AffiliateGuard from "@/modules/settings/helpers/AffiliateGuard";
import CustomDomainGuard from "@/modules/settings/helpers/CustomDomainGuard";
import SubscriptionGuard from "@/modules/settings/helpers/SubscriptionGuard";

export default [
  {
    path: "/settings",
    name: "SettingsLayout",
    beforeEnter: [AuthGuard.withVerifiedEmail, AccountGuard.withPermissions],
    children: [
      {
        path: "",
        name: "Settings",
        redirect: { name: "Account" },
      },
      {
        path: "account",
        name: "Account",
        component: () => import("@/modules/settings/views/TheAccount.vue"),
        meta: {
          title: "modules.settings.account.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "wallet",
        name: "Wallet",
        component: () => import("@/modules/settings/views/MyWallet.vue"),
        meta: {
          title: "modules.settings.wallet.meta",
          layout: "SettingsLayout",
          scope: ScopeType.Wallet,
        },
      },
      {
        path: "wallet/top-up",
        name: "WalletTopUp",
        component: () => import("@/modules/settings/views/WalletTopUp.vue"),
        meta: {
          title: "modules.settings.wallet.meta",
          breadcrumb: "Wallet",
          layout: "SettingsLayout",
          scope: ScopeType.Wallet,
        },
      },
      {
        path: "wallet/withdraw-options",
        name: "WithdrawOptions",
        component: () => import("@/modules/settings/views/WithdrawOptions.vue"),
        meta: {
          title: "modules.settings.wallet.meta",
          breadcrumb: "Wallet",
          layout: "SettingsLayout",
          scope: ScopeType.Wallet,
        },
      },
      {
        path: "billing",
        name: "Billing",
        component: () => import("@/modules/settings/views/TheBilling.vue"),
        meta: {
          title: "modules.settings.billing.meta",
          layout: "SettingsLayout",
          scope: ScopeType.Billing,
        },
      },
      {
        path: "payment-methods",
        name: "PaymentMethods",
        component: () => import("@/modules/settings/views/PaymentMethods.vue"),
        meta: {
          title: "modules.settings.paymentMethods.meta",
          layout: "SettingsLayout",
          scope: ScopeType.PaymentMethods,
        },
      },
      {
        path: "brands",
        name: "Brands",
        component: () => import("@/modules/settings/views/TheBrands.vue"),
        meta: {
          title: "modules.settings.brands.meta",
          layout: "SettingsLayout",
          scope: ScopeType.Brands,
        },
      },
      {
        path: "notifications",
        name: "NotificationSettings",
        component: () =>
          import("@/modules/settings/views/NotificationSettings.vue"),
        meta: {
          title: "modules.settings.notifications.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "subscription",
        name: "Subscription",
        component: () => import("@/modules/settings/views/TheSubscription.vue"),
        beforeEnter: [
          OnboardingGuard.withCompleteOnboarding,
          SubscriptionGuard.withSubscription,
        ],
        meta: {
          title: "modules.settings.subscription.meta",
          layout: "SettingsLayout",
          scope: ScopeType.MySubscription,
        },
      },
      {
        path: "subscription/subscribed",
        alias: "Subscribed",
        component: () => import("@/modules/settings/views/TheSubscription.vue"),
        beforeEnter: [
          OnboardingGuard.withCompleteOnboarding,
          SubscriptionGuard.subscribed,
        ],
        meta: {
          title: "modules.settings.subscription.meta",
          scope: ScopeType.MySubscription,
        },
      },
      {
        path: "subscription/payment-updated",
        alias: "PaymentUpdated",
        component: () => import("@/modules/settings/views/TheSubscription.vue"),
        beforeEnter: [
          OnboardingGuard.withCompleteOnboarding,
          SubscriptionGuard.paymentUpdated,
        ],
        meta: {
          title: "modules.settings.subscription.meta",
          scope: ScopeType.MySubscription,
        },
      },
      {
        path: "subscription/plans",
        name: "SubscriptionPlans",
        component: () =>
          import("@/modules/settings/views/SubscriptionPlans.vue"),
        meta: {
          title: "modules.settings.subscription.meta",
          layout: "SettingsLayout",
          breadcrumb: "Subscription",
          scope: ScopeType.MySubscription,
        },
      },
      {
        path: "request-feature",
        name: "RequestAFeature",
        meta: {
          title: "modules.settings.notifications.meta",
        },
        redirect: () => {
          window.open("https://joinbrands.canny.io/feature-requests", "_blank");
          return { name: "Dashboard" };
        },
      },
      {
        path: "affiliate-program",
        name: "AffiliateProgram",
        beforeEnter: AffiliateGuard.getStats,
        component: () =>
          import("@/modules/settings/views/AffiliateProgram.vue"),
        meta: {
          title: "modules.settings.affiliate.meta",
          layout: "SettingsLayout",
          scope: ScopeType.AffiliateProgram,
        },
      },
      {
        path: "white-labeling",
        name: "WhiteLabeling",
        beforeEnter: CustomDomainGuard.getHostnameList,
        component: () =>
          import("@/modules/settings/views/TheWhiteLabeling.vue"),
        meta: {
          title: "modules.settings.whiteLabeling.meta",
          layout: "SettingsLayout",
          scope: ScopeType.WhiteLabeling,
        },
      },
      {
        path: "white-labeling/connect-domain",
        name: "ConnectDomain",
        beforeEnter: [
          CustomDomainGuard.withWhiteLabelEnabled,
          CustomDomainGuard.withoutDomain,
        ],
        component: () => import("@/modules/settings/views/ConnectDomain.vue"),
        meta: {
          title: "modules.settings.whiteLabeling.connectDomain.meta",
          layout: "SettingsLayout",
          breadcrumb: "WhiteLabeling",
          scope: ScopeType.WhiteLabeling,
        },
      },
      {
        path: "white-labeling/setup-domain/:hostnameId(\\d+)",
        name: "SetupDomain",
        beforeEnter: [
          CustomDomainGuard.withWhiteLabelEnabled,
          CustomDomainGuard.withDomain,
        ],
        component: () => import("@/modules/settings/views/SetupDomain.vue"),
        meta: {
          title: "modules.settings.whiteLabeling.setupDomain.meta",
          layout: "SettingsLayout",
          breadcrumb: "WhiteLabeling",
          scope: ScopeType.WhiteLabeling,
        },
      },
      {
        path: "collaborators",
        name: "Collaborators",
        component: () =>
          import("@/modules/settings/views/TheCollaborators.vue"),
        meta: {
          title: "modules.settings.collaborators.meta",
          layout: "SettingsLayout",
          scope: ScopeType.Collaborators,
        },
      },
    ],
  },
];
